import debounce from "./utils/debounce";

window.addEventListener("DOMContentLoaded", function () {
    let current = 0;
    let interval;
    const duration = 5000;

    const slides = document.querySelectorAll(".feature-bar-feature");

    if (!slides.length) return;

    const init = () => {
        slides.forEach((item) => {
            item.classList.add("no-transition");
            item.style.opacity = 0;
            item.style.transform = 'translateY(50px)';
        });

        slides[current].style.opacity = 1;
        slides[current].style.transform = 'translateY(0)';
        slides.forEach((item) => item.classList.remove("no-transition"));

        interval = setInterval(function () {
            slides.forEach((item) => {
                item.style.opacity = 0;
                item.style.transform = 'translateY(50px)';
            });
            current = current !== slides.length - 1 ? current + 1 : 0;
            slides[current].style.opacity = 1;
            slides[current].style.transform = 'translateY(0)';
        }, duration);
    };

    const destroySlider = () => {
        if (interval) {
            slides.forEach((item) => {
                item.style.transform = 'translateY(0)';
                item.style.opacity = 1;
            });
            clearInterval(interval);
            interval = null;
        }
    };

    const checkSliders = () => {
        if (matchMedia("(max-width: 768px)").matches) {
            if (!interval) {
                init();
            }
        } else {
            destroySlider();
        }
    };

    window.addEventListener("resize", () => {
        debounce(checkSliders(), 200);
    });

    checkSliders();
});
