window.addEventListener("DOMContentLoaded", function () {
    document.querySelector(".navigation-checkbox")?.addEventListener("change", function () {
        const checked = this.checked;

        if (checked) {
            document.body.classList.add("nav-open");
        } else {
            document.body.classList.remove("nav-open");
        }
    });
});
