window.addEventListener('DOMContentLoaded', function () {
    const heroSlider = new Splide('.hero-splide', {
        perPage: 1,
        perMove: 1,
        loop: true,
        pagination: false,
        rewind: true,
        arrows: false,
        autoplay: true,
        arrowPath: "",
        type: 'fade',
        speed: 600,
        flickPower: 200,
        easing: 'cubic-bezier(.17,.67,.53,.97)',
        lazyLoad: true,
        preloadPages: 0
    });

    const heroTextSlider = new Splide('.hero-text-splide', {
        perPage: 1,
        perMove: 1,
        pagination: false,
        rewind: true,
        arrows: false,
        autoplay: false,
        type: 'fade',
        drag: false,
        easing: 'cubic-bezier(.17,.67,.53,.97)',
        speed: 1500,
        lazyLoad: true,
        preloadPages: 0
    });

    heroTextSlider.sync(heroSlider);

    // Lazy load background images
    heroSlider.on('active', ({slide}) => {
        slide.querySelector('.hero-slide').classList.remove('bg-lazy');
    })

    heroTextSlider.on('move', (newIndex) => {
        if (newIndex === 1 || newIndex === 2) {
            heroTextSlider.root.classList.add("dark");
        } else {
            heroTextSlider.root.classList.remove("dark");
        }
    })


    heroTextSlider.mount();
    heroSlider.mount();
});
